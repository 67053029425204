<template>
  <QPage
    padding
    class="bg-background"
  >
    <div class="q-mx-auto stl-content-container stl-content-container--large">
      <div class="row q-col-gutter-lg">
        <div class="col-12 text-center q-mt-lg">
          <AppContent
            tag="h1"
            :class="['text-weight-bolder', ($q.screen.gt.xs ? 'text-h2' : 'text-h3')]"
            path="pages.blog_vdc.title"
          />
          <AppContent
            tag="h2"
            class="q-my-none text-dark text-h5"
            path="pages.blog_vdc.subtitle"
          />
        </div>
      </div>
    </div>
    <div class="q-mx-auto stl-content-container stl-content-container--mlarge">
      <div class="row q-col-gutter-lg">
        <div
          v-if="categoriesOptions.length"
          class="col-12 flex q-mt-xl items-center"
        >
          <AppContent
            class="q-mx-md text-subtitle1"
            path="pages.blog_vdc.categories.selector"
          />
          <QSelect
            v-model="filters"
            :options="categoriesOptions"
            style="min-width:250px;"
            class="text-subtitle1 text-weight-bold"
            color="primary"
            label-color="grey-5"
            bg-color="grey-1"
            emit-value
            map-options
            outlined
          />
        </div>
        <div class="col-12">
          <template v-if="blogList.length && !loading">
            <QInfiniteScroll
              :offset="1200"
              @load="onLoad"
            >
              <div class="row">
                <template
                  v-for="(article, index) of blogList"
                  :key="index"
                >
                  <ArticleCard
                    class="col-12 col-sm-6 col-md-4 q-pa-sm"
                    :article="article"
                  />
                </template>
              </div>
            </QInfiniteScroll>
            <div
              v-if="!loadComplete && !loading"
              class="flex flex-center q-mt-xl"
            >
              <QBtn
                color="primary"
                icon="uil:plus"
                flat
                no-caps
                label="Afficher plus"
                @click="loadPosts()"
              />
            </div>
          </template>
          <div
            v-if="loading"
            class="flex flex-center"
          >
            <QSpinner
              color="primary"
              size="3rem"
              :thickness="5"
            />
          </div>
          <div
            v-if="!blogList.length"
            class="col-12"
          >
            <AppContent
              tag="h4"
              class="text-h5 text-center"
              path="pages.blog_vdc.no_content"
            />
          </div>
        </div>
      </div>
    </div>
  </QPage>
</template>

<script>
import { mapState } from 'vuex'
import ArticleCard from 'hc-core/components/cards/article-card'

export default {
  components: { ArticleCard },
  data () {
    return {
      filters: 'all',
      currentPage: 1,
      nbResultsPerPage: 6,
      loading: false,
      categories: undefined,
    }
  },
  computed: {
    ...mapState(['content', 'common']),
    blogList () { return this.$_.get(this.content, 'blogList', []) },
    blogPagination () { return this.content.blogPagination },
    loadComplete () { return this.blogPagination && this.currentPage === this.blogPagination.nbPages },
    categoriesOptions () {
      const final = []
      const categories = this.$_.get(this.common, 'config.custom.blog_vdc.categories')
      final.push({ label: this.$t({ id: 'pages.blog_vdc.categories.all' }), value: 'all' })
      if (categories) categories.forEach((cat, i) => { final.push({ label: this.$t({ id: `pages.blog_vdc.categories.${cat}` }), value: cat }) })
      return final
    }
  },
  watch: {
    $route: {
      deep: true,
      // immediate: true,
      handler: function (val, oldVal) {
        if (this.$route.query.category) {
          this.filters = this.$route.query.category
          if (this.filters !== 'all') this.categories = [this.filters]
        }
      }
    },
    filters: {
      deep: true,
      // immediate: true,
      handler: async function (val, oldVal) {
        this.currentPage = 1
        this.$store.commit('content/setBlogPagination', null)
        this.$store.commit('content/setBlogCategory', undefined)
        this.categories = undefined
        if (this.filters !== 'all') this.categories = [val]
        await this.loadPosts()
      }
    }
  },
  async mounted () {
    if (this.blogPagination) {
      if (this.$route.query.category) {
        this.filters = this.$route.query.category
        if (this.filters !== 'all') this.categories = [this.filters]
      }
      this.currentPage = this.blogPagination.page
    } else {
      await this.loadPosts()
    }
  },
  methods: {
    async loadPosts () {
      if (process.env.CLIENT) {
        try {
          if (this.loading) return
          this.loading = true
          if (this.blogPagination) this.currentPage = this.blogPagination.page + 1
          await this.$store.dispatch('content/fetchBlogContent', { locale: 'fr', page: this.currentPage, nbResultsPerPage: this.nbResultsPerPage, categories: this.categories })
        } catch (e) {
          this.useLogger(e)
        } finally {
          this.loading = false
        }
      }
    },
    async onLoad (index, done) {
      if (!this.loadComplete && this.currentPage < 5) {
        await this.loadPosts()
        done()
      }
    },
  }
}
</script>
